import { RecipientModel } from "components/models/RecipientModel";
import { IGenericAccount } from "components/models/YapilyModels/IGenericAccount";
import { State } from "state";

/**
 * seful when you’re receiving wallets one by one, for example, in real-time updates.
 * @param state current state
 * @param wallet wallet to add to the state
 * @returns the new state with the new added wallet
 */
export const setMakePaymentSenderWallet = (state: State, wallet: IGenericAccount): State => {
  return { 
    ...state, 
    makePayment: {
      ...state.makePayment,
      sender: wallet
    }
  }
};

export const setMakePaymentReceiver = (state: State, recipient: IGenericAccount): State => {
  return {
    ...state,
    makePayment: {
      ...state.makePayment,
      recipient: recipient
    }
  }
};

export const setMakePaymentAmountAndCurrency = (state: State, amount: number, currency: string): State => {
  return {
    ...state,
    makePayment: {
      ...state.makePayment,
      amount: amount,
      currency: currency
    }
  }
};

export const setMakePaymentNote = (state: State, note: string): State => {
  return {
    ...state,
    makePayment: {
      ...state.makePayment,
      note: note
    }
  }
};

export const setMakePaymentIsMK = (state: State, isMK: boolean): State => {
  return {
    ...state,
    makePayment: {
      ...state.makePayment,
      isMK: isMK
    }
  }
};

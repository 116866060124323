import ENDPOINTS from "endpoints";
import { WebSocketService } from "services/websocket.service";
import { autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-fetch-client";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { Store, connectTo } from "aurelia-store";
import { State } from "state";
import { WebSocketRequestModel } from "./models/WebSocketRequestModel";
import { default as env } from "../../config/environment.json";
import { AuthService } from "aurelia-auth";
import { WalletModel } from "./models/WalletModel";
import { IGenericAccount } from "components/models/YapilyModels/IGenericAccount";
import { YapilyBankModel } from "components/models/YapilyModels/YapilyBankModel";
import { YapilyBankDetailsModel } from "components/models/YapilyModels/YapilyBankDetailsModel";
import { YapilyAccountsModel } from "components/models/YapilyModels/YapilyAccountsModel";
import { IGenericTransaction } from "components/models/YapilyModels/IGenericTransaction";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { I18N } from "aurelia-i18n";
import { YapilyTransactionModel } from "components/models/YapilyModels/YapilyTransactionModel";
import { YapilyHttpClient } from "http_clients/YapilyHttpClient";
import localforage, { getItem } from "localforage";

@connectTo()
@autoinject
export class YapilyService {
  private subscriptions: Array<Subscription> = [];
  private banks: YapilyBankModel[] = [];

  constructor(
    private http: HttpClient,
    private eventAggregator: EventAggregator,
    private store: Store<State>,
    private authService: AuthService,
    private webSocketService: WebSocketService,
    private yapilyHttpClient: YapilyHttpClient,
    private i18n: I18N,
    private state: State
  ) {
    this.subscriptions.push(
      this.eventAggregator.subscribe("app.started", () => this.getBanks())
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "bank.add.many.state",
        (accounts: []) =>
          this.transformAccounts(accounts)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "banks.delete.one.backend",
        (account: IGenericAccount) =>
          this.deleteBank(account)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "banks.delete.one.state",
        (accountsId: string[]) =>
          this.deleteBankState(accountsId)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "bank.update.one.state",
        (data: { applicationUserId: string, accountId: string }) => {
          console.log("Received bank.update.one.state event:");
          console.log("Application User ID:", data.applicationUserId);
          console.log("Account ID:", data.accountId);
          this.getAccountInfoAndTransactions(data.applicationUserId, data.accountId);
        }
      )
    );
  }


  /**p
   * This function gets the wallets from the backend
   * will try websocket first, will fall back on ordinary http calls
   *
   */
  async getBanks() {
    const verb = "GET";
    const url = `${env.yapily_api}${ENDPOINTS.ALL_BANKS()}`;
    const body = "";
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const getBanksRequest = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(getBanksRequest);
  }

  async getAccountInfoAndTransactions(applicationUserId: string, accountId: string) {
    console.log("getAccountInfoAndTransactions called with:");
    console.log("applicationUserId:", applicationUserId);
    console.log("accountId:", accountId);
    console.log("getAccountInfoAndTransactions")
    console.log(applicationUserId)
    console.log(accountId)
    const verb = "GET";
    const url = `${env.yapily_api}${ENDPOINTS.ACCOUNT_AND_TRANSACTIONS(applicationUserId, accountId)}`
    const body = "";
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const getAccountInfoAndTransactionsRequest = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(getAccountInfoAndTransactionsRequest);
  }

  deleteBank(account: IGenericAccount) {
    const verb = "PATCH";
    const url = `${env.yapily_api}/bank-account-authorization/${account.id}`;
    const body = JSON.stringify({ isDeleted: true });
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const DeletetAllAccountsFromBank = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(DeletetAllAccountsFromBank);
  }

  deleteBankState(accountsId : string[]){
    this.store.dispatch('banks.delete.one.state' , accountsId)
  }

  patchAuthorization(bank: YapilyBankModel) {
    const verb = "PATCH";
    const url = `${env.yapily_api}/consent/${bank.institution}`;
    const body = JSON.stringify(bank);
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const getAllAccountsFromBank = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(getAllAccountsFromBank);
  }


  async getTransactionForAccount(account){
      let request = await this.yapilyHttpClient.fetch(`/bank/account/${account.id}/transactions`)
      let isok200 = await checkResponseStatus(request)
      let transaction = await isok200.json()
      if (!transaction) {
        return []
      }
      return transaction

  }

  async getPictureFromInstitutionId(institutionId){
    try{
      let request = await this.yapilyHttpClient.fetch(`/institution/${institutionId}`)
      let is200ok = await checkResponseStatus(request)
      let institution =  await is200ok.json()
      return institution.institution.media[0].source || ""
    } catch(e){
      console.log(e)
    }
  }

  detached() {
    this.subscriptions.forEach((subscription) => subscription.dispose());
  }

  async transformAccounts(response) {
    console.log("transformAccounts");
    console.log(response.account);
    const genericAccount: IGenericAccount = {
      id: response.account?.id,
      name: response.account?.nickname || response.account?.description || response.account?.accountNames?.[0]?.name || 'Unknown Account',
      balance: response.account?.balance,
      balance_expected: undefined,
      identification: 'Unknown',
      currency: response.account?.currency,
      institutionId: response.account?.institutionId,
      picture: await this.getPictureFromInstitutionId(response.account?.institutionId),
      isBlockchain: false,
      isError: false,
      transactions: [],
      isUk: response.account?.currency === 'GBP',
      isFavorite: false,
      isAvailableInLocalForage: false
    };

    // Safely check for accountBalances and expectedBalance
    if (Array.isArray(response.account?.accountBalances)) {
      const expectedBalance = response.account.accountBalances.find((balance: any) => balance.type === 'EXPECTED');
      if (expectedBalance && expectedBalance.balanceAmount) {
        genericAccount.balance_expected = expectedBalance.balanceAmount.amount;
      }
    }

    // Safely check for accountIdentifications and IBAN
    if (Array.isArray(response.account?.accountIdentifications)) {
      const ibanIdentification = response.account.accountIdentifications.find((identification: any) => identification.type === 'IBAN');
      if (ibanIdentification) {
        genericAccount.identification = ibanIdentification.identification || 'Unknown';
      }
    }

    // Safely process transactions
    if (Array.isArray(response.transactions.data)) {

      genericAccount.transactions = await this.processTransactions(response.transactions.data);
    }

    this.store.dispatch("banks.accounts.add.one", genericAccount);
  } 

  async processTransactions(transactions: YapilyTransactionModel[]): Promise<IGenericTransaction[]> {
    const transformedTransactions: IGenericTransaction[] = [];
  

    transactions.forEach(async transaction => {

      const transformedTransaction = await this.transformTransaction(transaction);
      transformedTransactions.push(transformedTransaction);
    });
  
    return transformedTransactions;
  }

  async transformTransaction(transaction: YapilyTransactionModel): Promise<IGenericTransaction> {
    try {
      // Transformation de la transaction
      const result: { imagePath: string; brandName: string } = this.getBrandPicture(transaction);
      let identification: string;
      let transactionName: string;
  
      if (transaction.payeeDetails) {
        if (transaction.payeeDetails.accountIdentifications[0].type === 'IBAN') {
          identification = transaction.payeeDetails.accountIdentifications[0].identification;
        } else {
          identification = transaction.payeeDetails.accountIdentifications[1].identification;
        }
        transactionName = !result.brandName ? transaction.payeeDetails.name : transaction.description || transaction.reference;
      } else {
        identification = '';
        transactionName = !result.brandName ? transaction.description : result.brandName;
      }
  
      // Création de l'objet transaction transformée
      const transformedTransaction: IGenericTransaction = {
        urlPic: result.imagePath,
        senderName: transactionName,
        _id: transaction.id,
        senderNote: transaction.description || transaction.reference,
        currency: transaction.transactionAmount.currency,
        amount: transaction.transactionAmount.amount,
        createdAt: new Date(transaction.date) || new Date(transaction.bookingDateTime),
        updatedAt: new Date(transaction.date) || new Date(transaction.bookingDateTime),
        senderIdentification: identification,
        walletId: transaction.id,  // Si nécessaire pour tes besoins
        transactionId: ""
      };
  
      return transformedTransaction;
    } catch (e) {
      console.error('Erreur lors de la transformation de la transaction : ', e);
      throw e;
    }
  }
  

  getBrandPicture(tx: any) {
  
    let reference = tx.reference || tx.description;
    const lowercaseReference = reference.toLowerCase();
    try {

      const brandMap: { [key: string]: { imagePath: string, brandName: string } } = {
        /* Others */
        "aliexpress": { imagePath: "img/brands/aliexpress.png", brandName: "AliExpress" },
        "kabel": { imagePath: "img/brands/kabel.jpg", brandName: "Kabel" },
        "la poste": { imagePath: "img/brands/laposte.png", brandName: "La Poste" },
        "bolt": {imagePath: "img/brands/bolt_eu.jpg", brandName: "Bolt"},
        "debuck": {imagePath: "img/brands/debuck_technolgies.png", brandName: "Debuck Technologies"},
        /* TELECOMs */ 
        "bouygues": {imagePath: "img/brands/bouygues.png", brandName: "Bouygues Telecom"},
        "o2": {imagePath: "img/brands/o2.png", brandName: "O²"},
        "orange": {imagePath: "img/brands/orange.png", brandName: "Orange"},
        "proximus": {imagePath: "img/brands/proximus.png", brandName: "Proximus"},
        "t-online": {imagePath: "img/brands/t_online.png", brandName: "T-Online"},
        "tadaam": {imagePath: "img/brands/tadaam.png", brandName: "Tadaam"},
        "telenet": {imagePath: "img/brands/telenet.png", brandName: "Telenet"},
        "sfr": {imagePath: "img/brands/sfr.png", brandName: "SFR"},
        "vodafone": {imagePath: "img/brands/vodafone.png", brandName: "Vodafone"},
        "voo": {imagePath: "img/brands/voo.png", brandName: "VOO"},
        "free": {imagePath: "img/brands/free.png", brandName: "Free"},
        "discord": {imagePath: "img/brands/discord.png", brandName: "Discord"},
        /* restaurants */
        "o-tacos": {imagePath: "img/brands/otacos.png", brandName: "O-Tacos"},
        "mcdonald": {imagePath: "img/brands/mcdonald.png", brandName: "McDonald"},
        "quick": {imagePath: "img/brands/quick.png", brandName: "Quick"},
        "uber eats": {imagePath: "img/brands/uber_eats.png", brandName: "Uber Eats"},
        "starbucks": {imagePath: "img/brands/starbucks.png", brandName: "Starbucks"},
        "burger king": {imagePath: "img/brands/burger_king.png", brandName: "Burger King"},
        "kfc": {imagePath: "img/brands/kfc.png", brandName: "KFC"},
        "exki": {imagePath: "img/brands/exki.png", brandName: "EXKi"},
        "pizza hut": {imagePath: "img/brands/pizzahut.png", brandName: "Pizza Hut"},
        "pianofabriek": {imagePath: "img/brands/pianofabriek.png", brandName: "GC Pianofabriek"},
        "cook & book": {imagePath: "img/brands/cookandbook.png", brandName: "Cook & Book"},
        "schievelavabo": {imagePath: "img/brands/schievelavabo.png", brandName: "Schievelavabo"},
        "makisu": {imagePath: "img/brands/makisu.png", brandName: "Makisu"},
        "zanzibar 1348": {imagePath: "img/brands/zanzibar.png", brandName: "Zanzibar"},
        "deliveroo": {imagePath: "img/brands/deliveroo.png", brandName: "Deliveroo"},
        "belchicken": {imagePath: "img/brands/belchicken.png", brandName: "Belchicken"},
        "be burger": {imagePath: "img/brands/beburger.png", brandName: "BeBurger"},
        "green mango": {imagePath: "img/brands/greenmango.png", brandName: "Green Mango"},
        "takeway": {imagePath: "img/brands/takeaway.png", brandName: "TakeAway"},
        /* transportation */
        "sncb": {imagePath: "img/brands/sncb.png", brandName: "SNCB"},
        "stib": {imagePath: "img/brands/stib.png", brandName: "STIB"},
        "uber": {imagePath: "img/brands/uber.png", brandName: "Uber"},
        "poppy": {imagePath: "img/brands/poppy.png", brandName: "Poppy"},
        "miles": {imagePath: "img/brands/miles.png", brandName: "Miles"},
        "eurostar": {imagePath: "img/brands/eurostar.png", brandName: "Eurostar"},
        /* Entertainment */
        "netflix": {imagePath: "img/brands/netflix.png", brandName: "Netflix"},
        "spotify": {imagePath: "img/brands/spotify.png", brandName: "Spotify"},
        "steam": {imagePath: "img/brands/steam.png", brandName: "Steam"},
        /* Big Retailers */
        "amazon": {imagePath: "img/brands/amazon.png", brandName: "Amazon"},
        "microsoft": {imagePath: "img/brands/microsoft.png", brandName: "Microsoft"},
        "google": {imagePath: "img/brands/google.png", brandName: "Google"},
        "sony": {imagePath: "img/brands/sony.png", brandName: "Sony"},
        "fiverr": {imagePath: "img/brands/fiverr.png", brandName: "Fiverr"},
        "kinepolis": {imagePath: "img/brands/kinepolis.png", brandName: "Kinepolis"},
        "okaidi": {imagePath: "img/brands/okaidi.png", brandName: "Okaidi"},
        "cora": {imagePath: "img/brands/cora.png", brandName: "Cora"},
        "auchan": {imagePath: "img/brands/auchan.png", brandName: "Auchan"},
        "cirque royal": {imagePath: "img/brands/cirqueroyal.png", brandName: "Cirque Royal"},
        "carrefour": {imagePath: "img/brands/carrefour.png", brandName: "Carrefour"},
        "wish": {imagePath: "img/brands/wish.png", brandName: "Wish"},
        "brico": {imagePath: "img/brands/brico.png", brandName: "Brico"},
        "lidl": {imagePath: "img/brands/lidl.png", brandName: "Lidl"},
        "aldi": {imagePath: "img/brands/aldi.png", brandName: "Aldi"},
        "delhaize": {imagePath: "img/brands/delhaize.png", brandName: "Delhaize"},
        "proxy": {imagePath: "img/brands/proxy.png", brandName: "Delhaize"},
        "ikea": {imagePath: "img/brands/ikea.png", brandName: "Ikea"},
        /* GAS STATION */
        "shell": {imagePath: "img/brands/shell.png", brandName: "Shell"},
        "total": {imagePath: "img/brands/total.png", brandName: "Total"},
        "esso": {imagePath: "img/brands/esso.png", brandName: "Esso"},
        "q8": {imagePath: "img/brands/q8.png", brandName: "Q8"},
        /* GENERIC */
        "pizza": {imagePath: "img/brands/pizza.png", brandName: "Pizza!"},
        "pharma": {imagePath: "img/brands/pharmacie.png", brandName: "Medic"},
        "parking": {imagePath: "img/brands/parking.png", brandName: "Parking"},
        "park bru": {imagePath: "img/brands/parking.png", brandName: "Parking BXL"},
        "spf finances": {imagePath: "img/brands/spf_finance.png", brandName: "SPF Finances"},
        "onss": {imagePath: "img/brands/onss.png", brandName: "ONSS"},
        /* Payment providers */
        "paypal": {imagePath: "img/brands/paypal.png", brandName: "Paypal"},
        "payconiq": {imagePath: "img/brands/payconiq.png", brandName: "Payconiq"},
        "bancontact": {imagePath: "img/brands/bancontact.png", brandName: "Bancontact"},
      };


      for (const key in brandMap) {
        if (lowercaseReference.includes(key.toLowerCase())) {
            return brandMap[key];
        }
      }


      return { imagePath: "img/bank_account_white.png", brandName: undefined };
    } catch (e) {
        console.error("Erreur lors de la recherche de l'image de la marque:", e);
        return { imagePath: "img/bank_account_white.png", brandName: undefined };
    }
  }
}
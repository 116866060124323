import { State } from "state";
import {User} from "../../components/models/UserModel";
import localforage from "localforage";

/**
 * seful when you’re receiving wallets one by one, for example, in real-time updates.
 * @param state current state
 * @param me user to add
 * @returns the new state with the new added wallet
 */
export const setMeAsUser = async (state: State, me: User): Promise<State> => {
  state = await localforage.getItem('State')
  return { 
    ...state, 
    me: {
      ...state.me,
      ...me
    }
  }
}


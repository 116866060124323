import { forEach } from "aurelia-auth";
import { WebSocketService } from "services/websocket.service";
import { autoinject, noView } from "aurelia-framework";
import { HttpClient } from "aurelia-fetch-client";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { Subscription as RXJSSubscription } from "rxjs";
import { Store, connectTo } from "aurelia-store";
import { State } from "state";
import { WebSocketRequestModel } from "./models/WebSocketRequestModel";
import { default as env } from "../../config/environment.json";
import { AuthService } from "aurelia-auth";
import { WalletModel } from "./models/WalletModel";
import { IGenericAccount } from "components/models/YapilyModels/IGenericAccount";
import { WalletHistoryModel } from "./models/WalletHistoryModel";
import { IGenericTransaction } from "components/models/YapilyModels/IGenericTransaction";
import { P2PWallet } from "bsv-wallet";
import { User } from "components/models/UserModel";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { PaymailWalletClient } from "http_clients/PaymailWalletClient";
import { InAppNotification } from "./models/InAppNotification";
import { I18N } from "aurelia-i18n";
import { getPublicProfile } from "./paymailClient";
import localforage from "localforage";

@connectTo()
@autoinject()
export class WalletService {
  private subscriptions: Array<Subscription> = []; //Event Aggregator
  private subscription: RXJSSubscription; //Store
  private walletsWithKeys: Map<string, P2PWallet> = new Map();
  private state: State;
  private me: User;
  private walletsFromState;

  /**
   * 1. From frontend send an event "my.event"
   * 2. service listens to the event
   * 3. service sends an WSS call to websocket.service
   * 4. webs
   */

  constructor(
    private http: HttpClient,
    private eventAggregator: EventAggregator,
    private store: Store<State>,
    private authService: AuthService,
    private webSocketService: WebSocketService,
    private paymailWalletHttpClient: PaymailWalletClient,
    private i18n: I18N
  ) {
    this.subscriptions.push(
      this.eventAggregator.subscribe("app.started", () => this.getWallets())
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe("wallets.update.one.backend", (walletId: string) => this.getWallet(walletId))
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "wallets.add.many",
        (wallets: WalletModel[]) => this.updateWalletsState(wallets)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "wallets.add.one.backend",
        (wallet: WalletModel) => this.createWalletBackend(wallet)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "wallets.add.one.state",
        (wallet: WalletModel) => this.updateWalletState(wallet)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "wallets.delete.one.backend",
        (wallet: WalletModel) => this.deleteWallet(wallet)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "wallet.transactions.add.many",
        (walletHistory: WalletHistoryModel[]) =>
          this.setTransactionsToWallet(walletHistory)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "wallet.transaction.add.one",
        (walletHistory: WalletHistoryModel) =>
          this.setTransactionToWallet(walletHistory)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe("wallet.transaction.latest.one", (txId) =>
        this.getLatestHistory(txId)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "wallets.delete.one.state",
        (wallet_id: string) => this.deleteWalletState(wallet_id)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe("wallet.transaction.submit.one", (body) =>
        this.postTransactionSubmit(body)
      )
    );
    this.subscription = this.store.state.subscribe(
      (state) => (this.state = state)
    );
  }

  deleteWalletState(wallet_id: string) {
    this.store.dispatch("wallets.delete.one.state", wallet_id);
  }

  /**
   * This function gets the wallets from the backend
   * will try websocket first, will fall back on ordinary http calls
   *
   */
  async getWallets() {
    const verb = "GET";
    const url = `${env.paymail_wallet_api}/wallet/`;
    const body = "";
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const getWalletRequest = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(getWalletRequest);
  }

  /**
   * This function gets the wallet from the backend
   * will try websocket first, will fall back on ordinary http calls
   *
   */
  async getWallet(walletId: string) {
    const verb = "GET";
    const url = `${env.paymail_wallet_api}/wallet/${walletId}`;
    const body = "";
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const getWalletRequest = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(getWalletRequest);
  }

  async updateWalletsState(wallets: WalletModel[]) {
    // Map WalletModel[] to IGenericAccount[]
      // Check if those walles are in the localStorage ?
      // If so add something isInLocalForage = true
      const genericAccounts: IGenericAccount[] = await Promise.all(wallets.map(async (wallet) => ({
        id: wallet._id,
        name: wallet.name,
        balance: wallet.balanceSatoshis,
        identification: wallet.paymail,
        currency: "BSV", // Assuming the currency is Bitcoin
        picture: "/img/currency/bitcoin/bitcoin_account_white.png", // Assuming no picture
        isBlockchain: true, // Assuming it's a blockchain wallet
        isError: false,
        isAvailableInLocalForage: await this.checkExistenceOfSeedInLocalForage(wallet)
      })));
  
      // Dispatch the action to update the state
      await this.store.dispatch("wallets.add.many", genericAccounts);
      //Fetch corresponding transactions
      this.getTransactions(genericAccounts);
  }

  deleteWallet(wallets: WalletModel) {
    const verb = "DELETE";
    const url = `${env.paymail_wallet_api}/wallet/${wallets.id}`;
    const body = "";
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const getTransactionsFromWalletRequest = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(getTransactionsFromWalletRequest);
  }

  /**
   * This function will compare all the state.wallets with the
   * seed phrases in the localForage of this device
   * if it finds it, it will set wallet[i].isisAvailableInLocalForage = true
   * @param wallet: wallet object from backend
   * @return: true if the seed in-s 
   * 
   */
  async checkExistenceOfSeedInLocalForage(wallet) {
    try{

      let myWalletsInLocalForage = JSON.parse(await localforage.getItem('wallets_' + this.state.me._id)) as Record<string, string>;
  
      if (!myWalletsInLocalForage) {
        return false;
      }
  
      if (myWalletsInLocalForage) {
      for(let i=0; i < Object.keys(myWalletsInLocalForage).length ; i++ ) {  
        if(Object.keys(myWalletsInLocalForage[i]).includes(wallet.paymail)){
          return true
        }
      }
      return false;
      }
    }catch(e){
      console.log(e)
      let myWalletsInLocalForage = JSON.parse(await localforage.getItem('wallets_' + this.state.me._id)) as Record<string, string>;
      for (const [paymail, mnemonic] of Object.entries(myWalletsInLocalForage)) {
        const wallet = new P2PWallet({ key: mnemonic } as any);
        await localforage.removeItem('wallets_' + this.state.me._id)
        await this.saveWallets(paymail, wallet )
      }

    }
  }
  /**
   * Retrieves transactions for multiple wallets.
   * Processes transactions for the currently selected wallet first, then for the rest.
   * @param {WalletModel[]} wallets - An array of wallet models.
   * @return {void}
   */
  getTransactions(wallets): void {
    // Get the index of the currently selected wallet
    const currentWalletIndex = this.state.selectedAccountIndex;

    // Process transactions for the current wallet (if it exists)
    if (currentWalletIndex >= 0 && currentWalletIndex < wallets.length) {
      const currentWallet = wallets[currentWalletIndex];
      this.processTransactionsForWallet(currentWallet);
    }

    // Process transactions for the rest of the wallets
    for (let i = 0; i < wallets.length; i++) {
      // Skip the current wallet (already processed)
      if (i !== currentWalletIndex) {
        const otherWallet = wallets[i];
        this.processTransactionsForWallet(otherWallet);
      }
    }
  }

  /**
   * Processes transactions for a specific wallet.
   * @param {WalletModel} wallet - The wallet model for which transactions should be processed.
   * @return {void}
   */
  private processTransactionsForWallet(wallet: IGenericAccount): void {
    // WebSocket request parameters
    const verb = "GET";
    const url = `${env.paymail_wallet_api}/wallet-history/${wallet.id}`;
    const body = "";
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";

    // Create WebSocket request model
    const getTransactionsFromWalletRequest = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );

    // Send WebSocket request for transactions
    this.webSocketService.sendMessage(getTransactionsFromWalletRequest);
  }


  createWalletBackend(wallet: WalletModel) {
    const verb = "POST";
    const url = `${env.paymail_wallet_api}/wallet/`;
    const body = wallet;
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const createWalletRequest = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(createWalletRequest);
  }

  getLatestHistory(txId: string) {
    const verb = "GET";
    const url = `${env.paymail_wallet_api}/wallet-history/latest/${txId}`;
    const body = "";
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const latestHistoryRequest = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(latestHistoryRequest);
  }

  postTransactionSubmit(body) {
    let walletId = this.state.makePayment.sender.id;
    const verb = "POST";
    const url = `${env.paymail_wallet_api}/wallet/${walletId}/submit`;
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const postSubmitRequest = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(postSubmitRequest);
  }

  async updateWalletState(wallet: WalletModel) {
    wallet.id = wallet.id || wallet._id;
    wallet.isBlockchain = wallet.isBlockchain || true; // Assuming it's a blockchain wallet
    wallet.balance =wallet.balance || wallet.balanceSatoshis || 0;
    wallet.identification =wallet.identification || wallet.paymail;
    wallet.currency = wallet.currency || "BSV";
    wallet.picture = wallet.picture || "/img/currency/bitcoin/bitcoin_account_white.png"; // Assuming no picture
    await this.store.dispatch("wallets.add.one.state", wallet);
    this.processTransactionsForWallet(wallet);
    wallet.isAvailableInLocalForage = wallet.isAvailableInLocalForage || false
  }

  /**
   * This function will find the corresponding wallet from these
   * history transactions and will set them up there.
   * @param walletHistory[] an array of wallet history which are transactions
   */
  async setTransactionsToWallet(walletHistories: WalletHistoryModel[]) {
    const genericTransactions: IGenericTransaction[] = walletHistories.map(
      (walletHistory) => ({
        _id: walletHistory.transactionId,
        currency: "BSV",
        feeSatoshis: walletHistory.feeSatoshis,
        isOneSat: walletHistory.isOneSat,
        inscriptionIds: walletHistory.inscriptionIds,
        walletId: walletHistory.walletId,
        transactionId: walletHistory.transactionId,
        senderName: walletHistory.counterpartyPaymail,
        senderIdentification: walletHistory.counterpartyPaymail,
        senderNote: walletHistory.senderNote,
        urlPic: "empty",
        amount: walletHistory.deltaSatoshis - walletHistory.feeSatoshis,
        createdAt: new Date(walletHistory.createdAt),
        updatedAt: new Date(walletHistory.updatedAt),
      })
    );
  
    // Store transactions in state before update
    this.store.dispatch("wallet.transactions.add.many", genericTransactions);
  
    // Create array to store updatedTransaction + update part
    const updatedTransactions: IGenericTransaction[] = [];
  
    try {
      for (const transaction of genericTransactions) {
        if (transaction.senderIdentification && transaction.senderIdentification.includes("@")) {
          let publicProfile = await this.getpublicProfileLocal(transaction.senderIdentification);
          let updatedTransaction = { ...transaction };
          if (publicProfile.name) {
            updatedTransaction.senderName = publicProfile.name;
          } else {
            updatedTransaction.senderName = transaction.senderIdentification;
          }
          updatedTransaction.urlPic = publicProfile.avatar;
  
          // If information don't match, put updatedTransaction into updatedTransactions before dispatch
          if (updatedTransaction.senderName !== transaction.senderName || updatedTransaction.urlPic !== transaction.urlPic) {
            updatedTransactions.push(updatedTransaction);
          }
        }
      }
  
      // If update, dispatch
      if (updatedTransactions.length > 0) {
        this.store.dispatch("wallet.transactions.add.many", updatedTransactions);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async getpublicProfileLocal(paymail) {
    try {
      const paymailRecipient = await getPublicProfile(paymail);
      return paymailRecipient
    } catch(e) {
      return {
        name: paymail,
        senderIdentification: paymail,
        avatar: "/img/currency/bitcoin/bitcoin_account_white.png"
      };
    }
  }

  async setTransactionToWallet(walletHistory: WalletHistoryModel) {
    const genericTransaction: IGenericTransaction = {
      _id: walletHistory.transactionId,
      urlPic: "empty",
      currency: "BSV",
      feeSatoshis: walletHistory.feeSatoshis,
      isOneSat: walletHistory.isOneSat,
      inscriptionIds: walletHistory.inscriptionIds,
      walletId: walletHistory.walletId,
      transactionId: walletHistory.transactionId,
      senderName: walletHistory.counterpartyPaymail,
      senderIdentification: walletHistory.counterpartyPaymail,
      senderNote: walletHistory.senderNote,
      amount: walletHistory.deltaSatoshis - walletHistory.feeSatoshis,
      createdAt: new Date(walletHistory.createdAt),
      updatedAt: new Date(walletHistory.updatedAt),
    };
    if (genericTransaction.senderIdentification && genericTransaction.senderIdentification.includes("@") && genericTransaction) {
      let publicProfile = await this.getpublicProfileLocal(
        genericTransaction.senderIdentification
      );
      genericTransaction.senderName = publicProfile.name;
      genericTransaction.urlPic = publicProfile.avatar;
    }
    let genericTransactionArray = [];
    genericTransactionArray.push(genericTransaction);
    this.store.dispatch(
      "wallet.transactions.add.many",
      genericTransactionArray
    );
    
    //Updating the wallet to get latest balance
    this.eventAggregator.publish("wallets.update.one.backend", walletHistory.walletId)

    //show in app notification if needeed
    const amountCurrency =
      genericTransaction.amount + genericTransaction.currency;
    // If I'm receiving money, show in app notif
    const foundWalletIndex = this.state.wallets.findIndex(wallet => 
      wallet.id === genericTransaction.walletId
      && genericTransaction.amount >= 0);
    if(foundWalletIndex >= 0) {
      const picture = "/img/currency/bitcoin/bitcoin_account_white.png";
      this.eventAggregator.publish("app.show.notification", 
        new InAppNotification(this.i18n.tr("notifications.you_received_money_title"), 
          this.i18n.tr("notifications.you_received_money", { amount: genericTransaction.amount , sender: genericTransaction.senderIdentification}),
          picture,
          3000
        ))

    } 
  }

  /**
   * Gets a signing Wallet
   */
  get(walletPaymail: string) {
    return this.walletsWithKeys.get(walletPaymail);
  }
  /**
   * Sets a signing Wallet
   */
  set(walletPaymail: string, walletUpdate: P2PWallet) {
    if (this.get(walletPaymail)) {
      this.remove(walletPaymail);
    }
    this.save(walletPaymail, walletUpdate);
  }

  /**
   * Returns the number of local private key for the wallets
   */
  count() {
    return this.walletsWithKeys.size;
  }
  /**
   * Add a wallet to wallets list
   */
  async save(walletPaymail: string, wallet: P2PWallet) {
    this.walletsWithKeys.set(walletPaymail, wallet);
    this.saveWallets(walletPaymail, wallet);
  }

  /**
   * Import from mnemonic
   */
  async importFromMnemonic(walletPaymail: string, mnemonic: string[]) {
    const wallet = new P2PWallet({ key: mnemonic.join(" ") } as any);
    this.save(walletPaymail, wallet);
    return wallet;
  }

  /**
   * Register all wallets in localStorage
   */
  private async saveWallets(walletPaymail: string, wallet?: P2PWallet) {
    if (!wallet) {
        return;
    }

    const mnemonic = wallet.getPrivateKey();
    
    const nameInStorage = "wallets_" + this.state.me._id;

    try {
        // Get back already registered wallets
        let alreadyExistingWallets: Record<string, string>[] = [];
        const existingWalletsString : string | null = await localforage.getItem(nameInStorage);
        if (existingWalletsString) {
            alreadyExistingWallets = JSON.parse(existingWalletsString);
        }

        // Verify if wallet already registered
        const existingWalletIndex = alreadyExistingWallets.findIndex(wallet => Object.keys(wallet)[0] === walletPaymail);
        
        if (existingWalletIndex !== -1) {
            // If already existing, update mnemonic
            alreadyExistingWallets[existingWalletIndex][walletPaymail] = mnemonic;
        } else {
            // If not, add wallet to the list
            alreadyExistingWallets.push({ [walletPaymail]: mnemonic });
        }

        // Register updated list in localStorage
        await localforage.setItem(nameInStorage, JSON.stringify(alreadyExistingWallets));
    } catch (error) {
        console.error("Error saving wallets:", error);
    }
}

  bind() {
    this.loadWalletsFromIndexDB();
  }

  /**
   * Load all wallets from localStorage
   */
  public async loadWalletsFromIndexDB() {
    try {
      let nameInStorage = "wallets_" + this.state.me._id;
      const walletsFromIndexDB = JSON.parse(
        await localforage.getItem(nameInStorage)
      );
      this.walletsFromState = this.state.wallets;

      this.walletsWithKeys = new Map();

      if (walletsFromIndexDB && this.walletsFromState) {
        await Promise.all(
          Object.keys(walletsFromIndexDB).map(async (walletPaymail) => {
            const key = walletsFromIndexDB[walletPaymail];
            const bsvWalletObject = new P2PWallet({
              key: key,
              network: "livenet",
            } as any);
            try {
              let { xpubIndex, id } = this.walletsFromState.find(
                (wallet) => wallet.identification === walletPaymail
              );
              bsvWalletObject.lastUsedIndex = xpubIndex;
              bsvWalletObject.id = id;
              this.set(walletPaymail, bsvWalletObject);
            } catch (e) {
            }
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Remove a wallet from wallets list
   */
  remove(name) {
    this.walletsWithKeys.delete(name);
    this.saveWallets(name);
  }

  detached() {
    this.subscriptions.forEach((subscription) => subscription.dispose());
    this.subscription.unsubscribe();
  }
}

import { forEach } from 'aurelia-auth';
import { WebSocketService } from 'services/websocket.service';
import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Store, connectTo } from 'aurelia-store';
import { State } from 'state';
import { WebSocketRequestModel } from './models/WebSocketRequestModel';
import { default as env } from '../../config/environment.json'
import { AuthService } from 'aurelia-auth';
import { WalletModel } from './models/WalletModel';
import { IGenericAccount } from 'components/models/YapilyModels/IGenericAccount';
import { WalletHistoryModel } from './models/WalletHistoryModel';
import { IGenericTransaction } from 'components/models/YapilyModels/IGenericTransaction';
import { User } from 'components/models/UserModel';
import { NotificationModel } from 'components/models/NotificationModel';
@connectTo()
@autoinject
export class NotificationsService {
  private subscriptions: Array<Subscription> = [];
  private state: State;

  constructor(private http: HttpClient, 
    private eventAggregator: EventAggregator, 
    private store: Store<State>,
    private authService: AuthService,
    private webSocketService: WebSocketService) {
    this.subscriptions.push(this.eventAggregator.subscribe('app.started', () => this.getNotifications()));
    this.subscriptions.push(this.eventAggregator.subscribe('notifications.add.many.state', (notifications: NotificationModel[]) => this.setNotificationsInState(notifications)));
    this.subscriptions.push(this.eventAggregator.subscribe('notifications.set.all.read', () => this.setAllNotificationsAsRead()))
    this.subscriptions.push(this.eventAggregator.subscribe('notification.update.one', (notification) => this.setNotificationInState(notification)))
  }

  /**p
   * This function gets the wallets from the backend
   * will try websocket first, will fall back on ordinary http calls
   * 
   */
  async getNotifications() {
    const verb = "GET";
    const url = `${env.notification_api}/notification/`;
    const body = "";
    const token = localStorage.getItem('aurelia_token');
    const direction = "outgoing";
    const getMyNotificationsRequest = new WebSocketRequestModel(verb, url, body, token, direction);
    this.webSocketService.sendMessage(getMyNotificationsRequest)
  }

  setAllNotificationsAsRead() {
    const notificationsNotRead: NotificationModel[] = this.state.notifications.filter((notification) => !notification.seen)
    notificationsNotRead.forEach(notification => {
      notification.seen = true;
      notification.status = "SEEN"
      const verb = "PATCH";
      const url = `${env.notification_api}/notification/${notification._id}`;
      const body = notification;
      const token = localStorage.getItem('aurelia_token');
      const direction = "outgoing";
      const setAllNotificationsAsReadRequest = new WebSocketRequestModel(verb, url, body, token, direction);
      this.webSocketService.sendMessage(setAllNotificationsAsReadRequest)
    });
  }

  setNotificationInState(notification: NotificationModel) {
    this.store.dispatch('notification.update.one', notification);
  }

  setNotificationsInState(notifications: NotificationModel[]) {
    this.store.dispatch('notifications.add.many', notifications);
  }

  detached() {
    this.subscriptions.forEach(subscription => subscription.dispose());
  }
}

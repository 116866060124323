import { IGenericAccount } from "components/models/YapilyModels/IGenericAccount";
import { IGenericTransaction } from "components/models/YapilyModels/IGenericTransaction";
import { YapilyBankDetailsModel } from "components/models/YapilyModels/YapilyBankDetailsModel";
import { State } from "state";
import localforage from "localforage";

/**
 * seful when you’re receiving bank one by one, for example, in real-time updates.
 * @param state current state
 * @param bank to add to the state
 * @returns the new state with the new added wallet
 */


export const addBank = async (state: State, bank: IGenericAccount): Promise<State> => {
  // Check if the bank with the same ID or IBAN already exists
  const isDuplicateId = state.wallets.some(existingBank => existingBank.id === bank.id);
  const isDuplicateIban = state.wallets.some(existingBank => existingBank.identification === bank.identification);
  
  const doesTransactionExist = state.wallets.some(existingBank => existingBank.transactions === bank.transactions);

  // Condition to check if both transaction and either ID or IBAN already exist
  if (doesTransactionExist && (isDuplicateId || isDuplicateIban)) {
    const updatedWallets = state.wallets.map(existingBank => {
      if (existingBank.id === bank.id || existingBank.identification === bank.identification) {
        return bank; // Replace the bank if ID or IBAN matches
      } else {
        return existingBank; // Keep the original bank
      }
    });

    // Save the updated wallets to localforage
    await localforage.setItem('State', updatedWallets);

    return { ...state, wallets: updatedWallets };
  }

  // If it's a duplicate in terms of ID or IBAN, return the original state
  if (isDuplicateId || isDuplicateIban) {
    return state;
  }

  // If it's not a duplicate, add the new bank to the state
  const newState = { ...state, wallets: [...state.wallets, bank] };

  // Save the new state to localforage
  await localforage.setItem('State', newState);

  return newState;
};



export const deleteBanks = (state: State, accountsId: string[]) => {
  const newState: State = { ...state };
  newState.wallets = newState.wallets.filter((wallet) => !accountsId.includes(wallet.id));
  return newState;
};


/**
 * useful when you’re receiving a batch of banks at once, for example,
 * when initializing the app or fetching updates.
 * @param state current state
 * @param banks to add to the state
 * @returns the new state with the new added wallets
 */
export const addBanks = (state: State, banks: IGenericAccount[]): State => {
  return { ...state, wallets: [...state.wallets, ...banks] };
};

export const addCountry = (state: State, country: string): State => {
  return { ...state, selectCountryBank: country };
};

export const addInstitution = (
  state: State,
  bank: YapilyBankDetailsModel
): State => {
  return { ...state, selectedBankToAdd: bank };
};

export const addTransaction = (state: State, transaction): State => {
  const newState: State = { ...state };
  let matchingIndex;
  for (let i = 0; i < newState.wallets.length; i++) {
    if (newState.wallets[i].identification === transaction.senderIdentification || transaction.payerDetails.accountIdentifications[0].identification) {
      matchingIndex = i
    }
    newState.wallets[matchingIndex].transactions = [...state.wallets[matchingIndex].transactions, transaction]
  }
  return newState
}

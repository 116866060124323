import { forEach } from "aurelia-auth";
import { WebSocketService } from "services/websocket.service";
import { autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-fetch-client";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { Store } from "aurelia-store";
import { State } from "state";
import { WebSocketRequestModel } from "./models/WebSocketRequestModel";
import { default as env } from "../../config/environment.json";
import { AuthService } from "aurelia-auth";
import { WalletModel } from "./models/WalletModel";
import { IGenericAccount } from "components/models/YapilyModels/IGenericAccount";
import { WalletHistoryModel } from "./models/WalletHistoryModel";
import { IGenericTransaction } from "components/models/YapilyModels/IGenericTransaction";
import { User } from "components/models/UserModel";
import { FriendModel } from "components/models/FriendModel";
import { InAppNotification } from "./models/InAppNotification";
import { I18N } from "aurelia-i18n";
import { RecipientModel } from "components/models/RecipientModel";
import { IgnorePlugin } from "webpack";
import { IGenericContact } from "components/models/IGenericContact";
import { UsersHttpClients } from "http_clients/UsersHttpClients";
import { checkResponseStatus } from "http_clients/checkResponseStatus";

@autoinject
export class ContactsService {
  private subscriptions: Array<Subscription> = [];

  constructor(
    private http: HttpClient,
    private eventAggregator: EventAggregator,
    private store: Store<State>,
    private authService: AuthService,
    private webSocketService: WebSocketService,
    private i18n: I18N,
    private state: State,
    private userHttpClient: UsersHttpClients,
  ) {

    //Contacts
    this.subscriptions.push(
      this.eventAggregator.subscribe("app.started", () => this.getContacts())
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "contacts.add.many",
        (contacts: IGenericContact[]) => this.updateStateWithAllContacts(contacts)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "contacts.patch.one.backend",
        (contact: FriendModel) => this.updateBackendWithUpdatedContact(contact)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "contacts.add.one.backend",
        (contact: FriendModel) => this.updateBackendWithOneContact(contact)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "contacts.add.one.state",
        (contact: IGenericContact) => this.updateStateWithOneContact(contact)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "contact.remove.one.backend",
        (contact: IGenericContact) => this.updateBackendWithDeleteContact(contact)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "contacts.remove.one.state",
        (contactId: string) => this.updateStateWithDeleteContact(contactId)
      )
    );

    ///////Recipients
    this.subscriptions.push(
      this.eventAggregator.subscribe("app.started", () => this.getMyRecipients())
    );
    this.subscriptions.push( //OK
      this.eventAggregator.subscribe(
        "recipients.add.many.state",
        (recipients: IGenericContact[]) => this.updateStateWithAllRecipients(recipients)
      )
    );
    this.subscriptions.push( //OK
      this.eventAggregator.subscribe(
        "recipient.add.one.backend",
        (recipient: IGenericAccount) => this.updateBackendWithOneRecipient(recipient)
      )
    );
    this.subscriptions.push( //OK
      this.eventAggregator.subscribe(
        "recipient.patch.one.backend",
        (recipient: IGenericAccount) => this.updateBackendWithPatchRecipient(recipient)
      )
    );
    this.subscriptions.push( //OK
      this.eventAggregator.subscribe(
        "recipientUK.add.one.backend",
        (recipient: IGenericAccount) => this.updateBackendWithOneRecipientUK(recipient)
      )
    );
    this.subscriptions.push( //OK
      this.eventAggregator.subscribe(
        "recipient.add.one.state",
        (recipient: IGenericAccount) => this.updateStateWithOneRecipient(recipient)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "recipient.remove.one.backend",
        (recipientId: string) => this.updateBackendWithDeleteRecipient(recipientId)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "recipient.remove.one.state",
        (recipientId: string) => this.updateStateWithDeleteRecipient(recipientId)
      )
    );
  }

  /**p
   * This function gets the wallets from the backend
   * will try websocket first, will fall back on ordinary http calls
   *
   */
  async getContacts() {
    const verb = "GET";
    const url = `${env.recipients_api}/friends/`;
    const body = ""; // no body for get
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const myContactsRequest = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(myContactsRequest);
  }

  updateBackendWithOneContact(contact: FriendModel) {
    const verb = "POST";
    const url = `${env.recipients_api}/friends/`;
    const body = JSON.stringify(contact);
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const myContactsRequest = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(myContactsRequest);
  }

  async updateStateWithAllContacts(contacts: IGenericContact[]) {

    let contactsList = await Promise.all(
      contacts.map(async (contact) => {
        return await this.transformContacts(contact);
      })
      );

      this.store.dispatch("contacts.add.many.state", contactsList);
      await this.getMyContactsInfo(contacts); //Detailled info
  }

  async updateStateWithOneContact(contact: IGenericContact) {
    //Check if status accepted, we create IgenericContact and its corresponding IGenericAccounts
    //else we add to state.contactsRequest
    const contactToAdd = await this.transformContacts(contact);
    this.store.dispatch("contacts.add.one.state", contactToAdd);
    const contactArray = [];
    contactArray.push(contactToAdd);
  }

  async transformContacts(contact) {
    const myId = ((await this.authService.getMe()) as User)._id;
    let accounts = [];
    //Handle old friendModel
    if (contact.friendAccounts) {
        contact.friendAccounts.forEach((friendAccount) => {
          const transformedAccount = {
              name: friendAccount.name,
              identification: friendAccount.identification || friendAccount.paymail,
          };

          accounts.push(transformedAccount);
        });
    } else if (contact.myWalletName) {
        contact.myWalletName.forEach((wallet) => {
          const transformedAccount = {
              name: wallet.name,
              identification: wallet.identification || wallet.paymail,
          };

          accounts.push(transformedAccount);
        });
    } else if (contact.inviter_recipients || contact.accepter_recipients) {
      if (myId && myId === contact.friend_id_fk) {
        contact.inviter_recipients.forEach((wallet) => {
          const transformedAccount = {
              name: wallet.name,
              identification: wallet.identification || wallet.paymail,
          };

          accounts.push(transformedAccount);
        });
      } else {
        contact.accepter_recipients.forEach((wallet) => {
          const transformedAccount = {
              name: wallet.name,
              identification: wallet.identification || wallet.paymail,
          };

          accounts.push(transformedAccount);
        });
      }
    }

    if (myId && myId === contact.friend_id_fk) {

      const friendId =
        myId === contact.friend_id_fk
          ? contact.requester_id_fk
          : contact.friend_id_fk;
        let request = await this.userHttpClient.fetch(`/profile/${friendId}`)
        let is200ok = await checkResponseStatus(request)
        let profile = await is200ok.json()
        
        let contactToReturn = {
          id: contact.requester_id_fk,
          name: profile.profile.displayName,
          email: profile.profile.email,
          urlPic: profile.profile.urlPic,
          accounts: accounts,
          status: contact.status,
          requester: contact.requester_id_fk,
          request_id: contact._id
      };

      return contactToReturn
        
    } else if (myId && myId === contact.requester_id_fk) {      

        let contactToReturn = {
            id: contact.friend_id_fk,
            name: contact.name || contact.requesterName,
            email: contact.email || contact.email_recipient,
            urlPic: contact.urlPic || contact.requesterProfilePicUrl,
            accounts: accounts,
            status: contact.status,
            requester: contact.requester_id_fk,
            request_id: contact._id
        };
        return contactToReturn
    }
}

  async getMyContactsInfo(contacts) {
    const myId = ((await this.authService.getMe()) as User)._id;


    contacts.forEach((contact) => {
      const friendId =
        myId === contact.friend_id_fk
          ? contact.requester_id_fk
          : contact.friend_id_fk;
      const verb = "GET";
      const url = `${env.users_api}/profile/${friendId}`;
      const body = ""; // no body for get
      const token = localStorage.getItem("aurelia_token");
      const direction = "outgoing";
      const getMyContactsProfileRequest = new WebSocketRequestModel(
        verb,
        url,
        body,
        token,
        direction
      );
      this.webSocketService.sendMessage(getMyContactsProfileRequest);
    });
  }

  async getMySingleContactInfo(contact) {
    const myId = ((await this.authService.getMe()) as User)._id;
    const friendId =
    myId === contact.friend_id_fk
      ? contact.requester_id_fk
      : contact.friend_id_fk;
    const verb = "GET";
    const url = `${env.users_api}/profile/${friendId}`;
    const body = ""; // no body for get
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const getMyContactsProfileRequest = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(getMyContactsProfileRequest);
  }

  updateBackendWithUpdatedContact(contact: FriendModel) {
    const verb = "PATCH";
    const url = `${env.recipients_api}/friends/${contact._id}`;
    const body = JSON.stringify(contact);
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const myContactsRequest = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(myContactsRequest);
  }

  updateBackendWithDeleteContact(contact: IGenericContact) {
    const verb = "DELETE";
    const url = `${env.recipients_api}/friends/${contact.request_id}`;
    const body = JSON.stringify(contact);
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const myContactsRequest = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(myContactsRequest);
  }

  updateStateWithDeleteContact(contactId) {
    this.store.dispatch("contacts.remove.one.state", contactId);
  }


  ///////RECIPIENTS

  async getMyRecipients() {
    const verb = "GET";
    const url = `${env.recipients_api}/recipients/owner_id`;
    const body = ""; // no body for get
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const myRecipientsRequest = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(myRecipientsRequest);
  }

  updateStateWithAllRecipients(recipients: IGenericContact[]) {
    let recipientsList
    recipientsList = recipients.map(this.transformRecipient);
    this.store.dispatch("recipients.add.many.state", recipientsList);
  }

  
  transformRecipient(recipient) {  
    if (recipient.isBlockchain) {
      return {
        id: recipient._id,
        name: recipient.name,
        urlPic: recipient.urlPic || "/img/currency/bitcoin/bitcoin_account_white.png",
        accounts: [{
          name: recipient.name,
          identification: recipient.iban,
          isBlockchain: true
        }],
        status: "accepted"
      };
    } else {
      return {
        id: recipient._id,
        name: recipient.name,
        urlPic: recipient.urlPic || "/img/bank_account_white.png",
        accounts: [{
          name: recipient.name,
          identification: recipient.iban,
          isBlockchain: false
        }],
        status: "accepted"
      };
    }
  }
  

  updateBackendWithOneRecipient(recipient: IGenericAccount) {
    const verb = "POST";
    const url = `${env.recipients_api}/recipients`;
    const body = JSON.stringify(recipient);
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const myContactsRequest = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(myContactsRequest);
  }

  updateBackendWithOneRecipientUK(recipient: IGenericAccount) {
    const verb = "POST";
    const url = `${env.recipients_api}/recipients`;
    const body = JSON.stringify(recipient);
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const myRecipientRequest = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(myRecipientRequest);
  }

  updateBackendWithPatchRecipient(recipient) {
    const verb = "PATCH";
    const url = `${env.recipients_api}/recipients/`+ recipient.id;
    const body = JSON.stringify(recipient);
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const myRecipientRequest = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(myRecipientRequest);
  }

  async updateStateWithOneRecipient(recipient: IGenericAccount) {
    let recipientToAdd = await this.transformRecipient(recipient);
    this.store.dispatch("recipient.add.one.state", recipientToAdd);
  }

  updateBackendWithDeleteRecipient(recipientId: string) {
    const verb = "DELETE";
    const url = `${env.recipients_api}/recipients/${recipientId}`;
    const body = ""
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const myContactsRequest = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(myContactsRequest);
  }

  updateStateWithDeleteRecipient(recipientId) {
    this.store.dispatch("recipient.remove.one.state", recipientId);
  }

  detached() {
    this.subscriptions.forEach((subscription) => subscription.dispose());
  }
}

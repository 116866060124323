import { forEach } from "aurelia-auth";
import { WebSocketService } from "services/websocket.service";
import { autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-fetch-client";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { Store, connectTo } from "aurelia-store";
import { State } from "state";
import { WebSocketRequestModel } from "./models/WebSocketRequestModel";
import { default as env } from "../../config/environment.json";
import { AuthService } from "aurelia-auth";
import { WalletModel } from "./models/WalletModel";
import { IGenericAccount } from "components/models/YapilyModels/IGenericAccount";
import { WalletHistoryModel } from "./models/WalletHistoryModel";
import { IGenericTransaction } from "components/models/YapilyModels/IGenericTransaction";
import { User } from "components/models/UserModel";
import { UsersHttpClients } from "http_clients/UsersHttpClients";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
@connectTo()
@autoinject
export class UserService {
  private subscriptions: Array<Subscription> = [];
  private state: State;

  constructor(
    private http: HttpClient,
    private eventAggregator: EventAggregator,
    private store: Store<State>,
    private authService: AuthService,
    private webSocketService: WebSocketService,
    private userHttpClient: UsersHttpClients
  ) {
    this.subscriptions.push(
      this.eventAggregator.subscribe("app.started", () => this.getMe())
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe("me.profile.subscription.update", () =>
        this.getMe()
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe("profile.update", (newProfile: User) =>
        this.updateProfileInBackend(newProfile)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "profile.updated",
        (newProfileInformation: User) =>
          this.updateProfileInStore(newProfileInformation)
      )
    );
  }

  /**p
   * This function gets the wallets from the backend
   * will try websocket first, will fall back on ordinary http calls
   *
   */
  async getMe() {
    const verb = "GET";
    const url = `${env.users_api}/profile/`;
    const body = "";
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const getMyProfileRequest = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(getMyProfileRequest);
  }

  async updateProfileInBackend(newProfile: User) {
    const verb = "PATCH";
    const url = `${env.users_api}/profile/`;
    const body = JSON.stringify(newProfile);
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const getMyProfileRequest = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(getMyProfileRequest);
  }

  /**
   * This function will receive a profile, for me, or my friends
   * if mine it'll add it to state.me;
   * else it'll find the state.contacts[].friend_id_fk === newProfile._id
   * and it will attach the info to it
   * @param newProfile the profile we just received
   */
  async updateProfileInStore(newProfile: User) {
    if (newProfile._id === (this.authService.getMe() as User)._id) {
      if (this.state && this.state.me.isSubscribed != newProfile.isSubscribed) {
        const response = await this.userHttpClient.fetch("/auth/token");
        const data = await response.json();
        this.authService.setToken(data.token);
      }
      this.store.dispatch("set.me.user", newProfile);
    } else this.store.dispatch("set.contact.profile.info", newProfile);
  }

  detached() {
    this.subscriptions.forEach((subscription) => subscription.dispose());
  }
}

export class InAppNotification {
  public title;
  public icon;
  public body;
  public tag;
  public timing;

  constructor(title?: string, body?: string, icon?: string, timing: number = 3000) {
    this.title = title;
    this.body = body;
    this.icon = icon;
    this.timing = timing;
  }
}

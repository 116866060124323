import { State } from "state";
import { FriendModel } from "components/models/FriendModel";
import { User } from "components/models/UserModel";
import { IGenericAccount } from "components/models/YapilyModels/IGenericAccount";
import { IGenericContact } from "components/models/IGenericContact";
import { RecipientModel } from "components/models/RecipientModel";

export const setContactsInState = (
  currentState: State,
  contacts: IGenericContact[]
) => {
  const newState: State = { ...currentState };

  const uniqueContacts = contacts.filter(contact =>
    newState.contacts.every(existingContact => existingContact.id !== contact.id)
  );

  newState.contacts = [...currentState.contacts, ...uniqueContacts];

  return newState;
};

export const setSearchInState = (
  currentState: State,
  search: string
) => {
  const newState: State = { ...currentState };
  newState.searchSharedAccounts = search
  return newState;
};

export const updateContactInState = (
  state: State,
  contactToAdd: IGenericContact
): State => {
  // Create a shallow copy of the state to avoid mutating the original state
  const newState: State = { ...state };

  // Iterate through each transaction and update the corresponding wallet in the state
  const contactIndex = newState.contacts.findIndex(
    (contact) => contactToAdd.id === contact.id
  );
  if (contactIndex == -1) {
    newState.contacts.push(contactToAdd);
    newState.contacts.sort((a, b) => a.name?.localeCompare(b.name));
    return newState;
  } else {
    newState.selectedContactForDetails?.id == contactToAdd.id ||
    newState.selectedContactForDetails?.id == contactToAdd.id
      ? (newState.selectedContactForDetails = contactToAdd)
      : "";
    newState.contacts[contactIndex] = contactToAdd;
    newState.contacts.sort((a, b) => a.name?.localeCompare(b.name));
    return newState;
  }
};

export const setContactInfoInState = (state: State, userInfo: User) => {

    const newState: State = { ...state };
  

    const myself = newState.me._id;

    const contact = newState.contacts?.find(
      (contact: IGenericContact) =>
      (contact.id === userInfo._id || (contact.id === userInfo._id && myself !== userInfo._id))
      );
  

    if (!contact) {
  
      newState.contacts.sort((a, b) => a.name?.localeCompare(b.name));
      return newState;
    } else {


      contact.name = userInfo.displayName;
      contact.urlPic = userInfo.urlPic;
      contact.email = userInfo.email;

      newState.contacts.sort((a, b) => a.name?.localeCompare(b.name));
      return newState;
    }
};

export const setContactToAddSelectedInState = (
  state: State,
  userInfo: User
) => {
  const newState: State = { ...state };
  newState.selectedContactToAdd = userInfo;

  return newState;
};

export const setContactToAcceptInState = (
  state: State,
  contact: IGenericContact
) => {
  const newState: State = { ...state };
  newState.selectedContactToAccept = contact;

  return newState;
};

export const deleteContact = (state: State, contactId: string) => {
  const newState: State = { ...state };

  newState.contacts = newState.contacts.filter(
    (contact) => contact.request_id !== contactId
  );
  if (contactId === newState.selectedContactForDetails?.id)
    newState.selectedContactForDetails = undefined;

  return newState;
};

export const setContactToGetDetails = (state: State, contact: IGenericContact) => {
  const newState: State = { ...state };
  newState.selectedContactForDetails = contact;

  return newState;
};

//Recipients

export const setRecipientsInState = (
  currentState: State,
  recipients: IGenericContact[]
) => {
  const newState: State = { ...currentState };

  const uniqueRecipients = recipients.filter(contact =>
    newState.contacts.every(existingContact => existingContact.id !== contact.id)
  );

  newState.contacts = [...currentState.contacts, ...uniqueRecipients];

  return newState;
};

export const deleteRecipient = (state: State, beneficiaryId: string) => {
  const newState: State = { ...state };
  newState.contacts = newState.contacts.filter(
    (contact) => contact.id !== beneficiaryId
  );

  return newState;
};

export const addOneRecipientInState = (state: State, recipient) => {
  const updatedContacts = state.contacts.map(contact => {
    if (contact.id === recipient.id) {
      const updatedContact = {
        ...contact,
        accounts: [
          {
            ...contact.accounts[0],
            name: recipient.name,
            identification: recipient.iban || recipient.account_number
          }
        ]
      };
      return updatedContact;
    } else {
      return contact;
    }
  });

  const contactExists = state.contacts.some(contact => contact.id === recipient.id);

  const newState: State = {
    ...state,
    contacts: contactExists
      ? updatedContacts.sort((a, b) => a.name?.localeCompare(b.name))
      : [...state.contacts, recipient].sort((a, b) => a.name?.localeCompare(b.name))
  };

  return newState;
};

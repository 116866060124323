import { autoinject } from "aurelia-framework";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { Store, connectTo } from "aurelia-store";
import { default as env } from "../../config/environment.json";
import { WebSocketRequestModel } from "./models/WebSocketRequestModel";
import { WalletModel } from "./models/WalletModel";
import { YapilyBankModel } from "components/models/YapilyModels/YapilyBankModel";
import { YapilyBankDetailsModel } from "components/models/YapilyModels/YapilyBankDetailsModel";
import { YapilyAccountsModel } from "components/models/YapilyModels/YapilyAccountsModel";
import { AuthService } from "aurelia-auth";
import { WalletHistoryModel } from "./models/WalletHistoryModel";
import { User } from "components/models/UserModel";
import { NotificationModel } from "components/models/NotificationModel";
import { FriendModel } from "components/models/FriendModel";
import { DeletedObject } from "./models/DeletedObject";
import { RecipientModel } from "components/models/RecipientModel";
import { IGenericContact } from "components/models/IGenericContact";
import { IGenericAccount } from "components/models/YapilyModels/IGenericAccount";
import { I18N } from "aurelia-i18n";
import { Router } from "aurelia-router";
import { getPublicProfile } from "./paymailClient";
import { State } from "state";

type BackendResponse =
  | { wallets?: WalletModel[] }
  | Array<YapilyBankModel>
  | YapilyBankDetailsModel
  | WalletHistoryModel
  | YapilyAccountsModel
  | { txid: string; walletHistory: WalletHistoryModel; counterpartyUserId: string;}
  | {success: boolean; message: string; accounts: YapilyAccountsModel};

@connectTo()
@autoinject
export class WebSocketService {
  private socket: WebSocket;
  private subscription: Subscription;
  private me: User

  constructor(
    private eventAggregator: EventAggregator,
    private store: Store<State>,
    private authService: AuthService,
    private i18n: I18N,
    private router: Router,
    private state: State
  ) {
    this.socket = new WebSocket(env.paymail_wallet_wss);
    this.InitiateWSSConnection();
  }

  /**
   * This function will initiate Paymail backend WSS connection and
   * handle sending and incoming messages
   */
  InitiateWSSConnection() {
    this.socket.onopen = (event) => {
      this.socket.send(
        JSON.stringify({
          body: "ping",
          token: localStorage.getItem("aurelia_token"),
          url: "mybanka.eu",
          verb: "GET",
        })
      );
      console.log("WebSocket is connected.", event);
    };

    this.socket.onmessage = (event) => {
      this.handleReceiveWebSocketMessage(event.data);
    };

    this.socket.onerror = (event) => {
      console.error("WebSocket error observed:", event);
    };

    this.socket.onclose = (event) => {
      console.log("WebSocket is closed now.", event);
    };
  }

  /**
   * This function handles every websocket message received
   * from the server.
   * It analyze the content of the message and take action
   * depending on the content.
   * A little bit of guessing but should work most of the time.
   * When I have more infinite time i'll fix.
   * @param message
   */
  handleReceiveWebSocketMessage(message: string): void {
    // Handle received message

    // Parse the JSON string into a JavaScript object
    let responseFromBackend: BackendResponse;
    try {
      responseFromBackend = JSON.parse(message);
    } catch (e) {
      if (message === "Unauthorized") {
        this.authService.logout("signin");
      }
    }

    if (!responseFromBackend) {
      console.error("Unknown response from backend:", responseFromBackend);
      return;
    }

    if (
      Array.isArray(responseFromBackend) &&
      responseFromBackend.length > 0 &&
      "institution" in responseFromBackend[0] &&
      "application_user_id" in responseFromBackend[0] &&
      "account_id" in responseFromBackend[0]
    ) {
      this.handleBanks(responseFromBackend);
    }

    // else if (
    //   "data" in responseFromBackend &&
    //   Array.isArray(responseFromBackend.data)
    // ) {
    //   try {
    //     this.handleTransactions(responseFromBackend);
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }
    
    else if ("bank" in responseFromBackend) {
      this.handlePostConsentBank(responseFromBackend.bank as YapilyBankModel);
    } else if ("authorizations" in responseFromBackend) {
      this.handleAuthorizations(responseFromBackend.authorizations as any);
    } else if ("account" in responseFromBackend ) {
      this.handleAllAccount(responseFromBackend as any)
      /* Contacts */
    } else if ("contacts" in responseFromBackend) {
      this.handleContacts(responseFromBackend.contacts as IGenericContact[]);
    } else if ("contact" in responseFromBackend) {
      this.handleContact(responseFromBackend.contact as IGenericContact);

      //Recipient
      
    } else if ("recipients" in responseFromBackend) {
     this.handleRecipients(responseFromBackend.recipients);
    } else if ("recipient" in responseFromBackend) {
      this.handleRecipient(responseFromBackend.recipient);
    } 
      /* 
    Wallets
    */
      else if ("wallets" in responseFromBackend) {
      this.handleWallets(responseFromBackend.wallets);
    } else if ("wallet" in responseFromBackend) {
      this.handleWallet(responseFromBackend.wallet as WalletModel);
    } else if ("walletHistorys" in responseFromBackend) {
      this.handleWalletHistorys(
        responseFromBackend.walletHistorys as WalletHistoryModel[]
      );
    } else if ("walletHistory" in responseFromBackend) {
      this.handleWalletHistory(
        responseFromBackend.walletHistory as WalletHistoryModel, responseFromBackend.counterpartyUserId as string
      );	
    } else if ("notifications" in responseFromBackend) {
      /* 
    Notifications 
    */
      this.handleNotifications(
        responseFromBackend.notifications as NotificationModel[]
      );
    } else if ("notification" in responseFromBackend) {
      this.handleUpdateNotification(
        responseFromBackend.notification as NotificationModel
      );
    } else if (
      /* 
    Profile
    */
      "countries" in responseFromBackend &&
      "name" in responseFromBackend &&
      "id" in responseFromBackend
    ) {
      this.handleDetailsBank(responseFromBackend);
    } else if ("profile" in responseFromBackend) {
      this.handleUpdateProfile(responseFromBackend.profile as User);
    } else if ("deleted" in responseFromBackend) {
      this.handleDelete(responseFromBackend.deleted as DeletedObject);
    } else if ("error" in responseFromBackend) {
      this.handleErrorMessage(responseFromBackend.error as Object)
    } else {
      console.error("Unknown response from backend:", responseFromBackend);
    }
  }

  /* Handle Wallets */
  /**
   * This function will trigger a State update with the
   * new wallets received from backend
   *
   * all pages should automatically update.
   * @param wallets list of user's wallet
   */

  handleWallets(wallets: WalletModel[]) {
    // Handle wallets
    this.eventAggregator.publish("wallets.add.many", wallets);
  }

  handleWallet(wallet: WalletModel) {
    if (wallet.isDeleted === true) {
      this.eventAggregator.publish("wallets.delete.one.state", wallet._id);
      return;
    }
    // Handle wallets
    this.eventAggregator.publish("wallets.add.one.state", wallet);
  }

  handleWalletHistorys(walletHistorys: WalletHistoryModel[]) {
    this.eventAggregator.publish(
      "wallet.transactions.add.many",
      walletHistorys
    );
  }

  async handleWalletHistory(walletHistory: WalletHistoryModel, counterpartyUserId: string) {
    this.me = await this.authService.getMe();
    let myId = this.me._id
    if(myId === counterpartyUserId as any) {
      this.handleWalletSubmit()
    }
    else {
     this.eventAggregator.publish("wallet.transaction.add.one", walletHistory);
     setTimeout(() => {this.eventAggregator.publish("wallets.update.one.backend", walletHistory.walletId), 1000})
    } 
  }

  handleWalletSubmit() {
    this.eventAggregator.publish("app.started"); 
  }

  /* 
  Handle contacts & recipients
  */
  handleContacts(contacts: IGenericContact[]) {
    this.eventAggregator.publish("contacts.add.many", contacts);
  }

  handleDelete(deletedObject: DeletedObject) {
    if ("contact_id" in deletedObject) {
      this.eventAggregator.publish(
        "contacts.remove.one.state",
        deletedObject.contact_id
      );
    }
    if ("bank_id" in deletedObject) {
      this.eventAggregator.publish(
        "banks.delete.one.state",
        deletedObject.bank_id
      );
    }
    if ("wallet_id" in deletedObject) {
      this.eventAggregator.publish(
        "wallets.delete.one.state",
        deletedObject.wallet_id
      );
    }
    if ("recipient_id" in deletedObject) {
      this.eventAggregator.publish(
        "recipients.delete.one",
        deletedObject.recipient_id
      );
    }
    if ("notification_id" in deletedObject) {
      this.eventAggregator.publish(
        "notifications.delete.one",
        deletedObject.notification_id
      );
    }
    if ("beneficiary_id" in deletedObject) {
      this.eventAggregator.publish(
        "recipient.remove.one.state",
        deletedObject.beneficiary_id
      );
    }
  }

  handleContact(contact: IGenericContact) {
    this.eventAggregator.publish("contacts.add.one.state", contact);
  }

  //Handle Recipients

  handleRecipients(recipients) {
    this.eventAggregator.publish("recipients.add.many.state", recipients);
  }

  handleRecipient(recipient) {
    this.eventAggregator.publish("recipient.add.one.state", recipient);
  }

  /* Handles banks */

  handleBanks(banks: YapilyBankModel[]) {
    this.eventAggregator.publish("banks.add.many", banks);
  }

  handlePostConsentBank(bank: YapilyBankModel) {
    const bankArray = [];
    bankArray.push(bank);
    this.eventAggregator.publish("banks.details.add.one", bankArray);
    this.eventAggregator.publish("app.started"); 
  }

  handleDetailsBank(bankDetails: YapilyBankDetailsModel) {
    this.eventAggregator.publish("banks.details.add.one", bankDetails);
  }

  handleTransactions(bankAccounts: YapilyAccountsModel) {
    //this.eventAggregator.publish("bank.accounts.add.many", bankAccounts);
  }

  handleUpdateProfile(newProfile: User) {
    this.eventAggregator.publish("profile.updated", newProfile);
  }

  handleAuthorizations(authorizations: any) {
    authorizations.forEach(authorization => {
      console.log("Authorization object:", authorization);
      console.log("Application User ID:", authorization.applicationUserId);
      console.log("Account IDs:", authorization.accountsIds);

      authorization.accountsIds.forEach(accountId => {
        console.log("Processing account ID:", accountId);
        this.eventAggregator.publish("bank.update.one.state", {
          applicationUserId: authorization.applicationUserId,
          accountId: accountId
        });
      });
    });
  };

  handleAllAccount(response) {
    this.eventAggregator.publish("bank.add.many.state", response);
  }

  

  
  /* 
Notification handlers
*/
  handleNotifications(notifications: NotificationModel[]) {
    this.eventAggregator.publish("notifications.add.many.state", notifications);
  }

  handleUpdateNotification(notification: NotificationModel) {
    this.eventAggregator.publish("notification.update.one", notification);
  }

  handleErrorMessage(error) {
    if (error.name == "MongoServerError") {
      alert(this.i18n.tr("home.error.create_bitcoin_wallet_exists_already"));
      this.router.navigateToRoute("name_bitcoin_wallet");
    }
  }

  sendMessage(request: WebSocketRequestModel): void {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(request.toString());
    } else {
      console.error(
        "WebSocket is not open. Ready state is:",
        this.socket.readyState
      );
    }
  }

  async getpublicProfileLocal(paymail) {
    try {
      const paymailRecipient = await getPublicProfile(paymail);
      return paymailRecipient
    } catch(e) {
      return {
        name: paymail,
        senderIdentification: paymail,
        avatar: "/img/currency/bitcoin/bitcoin_account_white.png"
      };
    }
  }
}

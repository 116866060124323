import { YapilyTransactionModel } from "components/models/YapilyModels/YapilyTransactionModel";
import { YapilyAccountsModel } from "components/models/YapilyModels/YapilyAccountsModel";
import { YapilyAccountModel } from "components/models/YapilyModels/YapilyAccountModel";
import { YapilyBankModel } from "components/models/YapilyModels/YapilyBankModel";
import { YapilyTransactionsModel } from "components/models/YapilyModels/YapilyTransactionsModel";
import ENDPOINTS from "endpoints";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { autoinject, computedFrom, inject } from "aurelia-framework";
import { YapilyHttpClient } from "http_clients/YapilyHttpClient";
import { SubscriptionHttpClient } from "http_clients/SubscriptionHttpClient";
import { YapilyBanksDetailsModel } from "components/models/YapilyModels/YapilyBanksDetailsModel";
import { YapilyBankDetailsModel } from "components/models/YapilyModels/YapilyBankDetailsModel";
import { Subscription } from "pages/profile/subscription/subscription";
import { Router } from "aurelia-router";
import { SingletonService } from "singleton";
import t from "moment";
import { User } from "components/models/UserModel";

@autoinject()
export class BankaService {
  private me: User;

  constructor(
    private yapilyhttpclient: YapilyHttpClient,
    private subscriptionHttpClient: SubscriptionHttpClient,
    private router: Router,
    private singleton: SingletonService
  ) {}

  /**
   * @return: toutes les banks consenties par le user
   */
  async getBanksAvailable(country: string): Promise<YapilyBanksDetailsModel> {
    let httpRequest = await this.yapilyhttpclient.fetch(
      ENDPOINTS.BANKS_AVAILABLE(country)
    );
    let is200OK = await checkResponseStatus(httpRequest);
    let banks_available = await is200OK.json();
    return banks_available;
  }

  /**
   * @return: toutes les banks consenties par le user
   */
  async getBanks(): Promise<Array<YapilyBankModel>> {
    let httpRequest = await this.yapilyhttpclient.fetch(ENDPOINTS.ALL_BANKS());
    let is200OK = await checkResponseStatus(httpRequest);
    return await is200OK.json();
  }

  /**
   * @return toutes les banks avec les comptes injectés à l'interieur
   */
  async getPopulatedBanks(): Promise<Array<YapilyBankModel>> {
    let httpRequest;
    let is200OK;
    let banks: Array<YapilyBankModel>;

    try {
      httpRequest = await this.yapilyhttpclient.fetch(ENDPOINTS.ALL_BANKS());
      is200OK = await checkResponseStatus(httpRequest);
      banks = await is200OK.json();
    } catch (error) {
      this.handleError(error);
    }

    banks = this.cleanDoubleBanks(banks);

    // for (let i = 0; i < banks.length; i++) {
    //   banks[i].institutionDetails = await this.getBankDetails(banks[i]);

    //   banks[i].accounts = [];
    //   banks[i].accounts = await this.getAccounts(banks[i]);
    // }

    return banks;
  }

  cleanDoubleBanks(banksInDouble) {
    let singleBanks = [];
    let singleBanksModelToReturn = [];

    for (let i = 0; i < banksInDouble.length; i++) {
      if (!singleBanks.includes(banksInDouble[i].institution)) {
        singleBanks.push(banksInDouble[i].institution);
        singleBanksModelToReturn.push(banksInDouble[i]);
      }
    }

    return singleBanksModelToReturn;
  }

  /**
   * Handle backend errors
   * @param: error
   * @return: Nothing but redirect user
   */
  handleError(error) {
    // Consent needs to be refreshed
    if (
      error.errors.code == 403 &&
      error.errors.message.includes("AWAITING_RE_AUTHORIZATION") &&
      this.singleton.getMe().isSubscribed == false
    ) {
      this.router.navigateToRoute("reauthorize_users");
    }
  }

  // async getBankDetails(bank: YapilyBankModel): Promise<YapilyBankDetailsModel> {
  //   try {
  //     let httpRequest = await this.yapilyhttpclient.fetch(
  //       ENDPOINTS.SPECIFIC_BANK(bank.institution)
  //     );
  //     let is200OK = await checkResponseStatus(httpRequest);
  //     return await is200OK.json();
  //   } catch (e) {
  //     console.log(e);
  //     return new YapilyBankDetailsModel();
  //   }
  // }

  /**
   * @return All accounts for given bank
   */
  // async getAccounts(bank: YapilyBankModel): Promise<Array<YapilyAccountModel>> {
  //   let httpRequest;
  //   let is200OK;
  //   let receivedAccount;
  //   try {
  //     httpRequest = await this.yapilyhttpclient.fetch(
  //       ENDPOINTS.ALL_ACCOUNTS_FROM_BANK(bank.institution)
  //     );
  //     is200OK = await checkResponseStatus(httpRequest);
  //     receivedAccount = await is200OK.json();
  //   } catch (error) {
  //     let errorArray = [];
  //     error.consentExpired = true;
  //     errorArray.push(error);
  //     return errorArray;
  //   }
  //   return receivedAccount.data;
  // }

  /**
   * @return All transactions for given account
   */
  // async getTransactions(
  //   bank: YapilyBankModel,
  //   account: YapilyAccountModel
  // ): Promise<YapilyTransactionsModel> {
  //   try {
  //     let httpRequest = await this.yapilyhttpclient.fetch(
  //       ENDPOINTS.TRANSACTIONS(bank.institution, account.id)
  //     );
  //     let is200OK = await checkResponseStatus(httpRequest);
  //     const receivedTransactions: YapilyTransactionsModel =
  //       await is200OK.json();

  //     return receivedTransactions;
  //   } catch (e) {
  //     let empty: YapilyTransactionsModel = new YapilyTransactionsModel();
  //     empty.data = [];
  //     return empty;
  //   }
  // }

  /**
   * @return returns a bank if bank consented
   * or an empty array if not consented
   */

  async getConsent({ consent, application_user_id, user_uuid, institution }) {
    let method = "POST";

    let fetch_params = {
      method,
      body: JSON.stringify({
        consent,
        application_user_id,
        user_uuid,
        institution,
      }),
    };

    let request = await this.yapilyhttpclient.fetch(
      "/consent",
      fetch_params
    );
    let is200Ok = await checkResponseStatus(request);
    let links = await is200Ok.json();
    return links;
  }

  async initiatSubscription(
    institutionId: string,
    type: "abonnemment" | "abonnemment++",
    iban: string,
    currency: string
  ) {
    const request = await this.subscriptionHttpClient.fetch(
      "/subscription/get-authorization",
      {
        method: "POST",
        body: JSON.stringify({ type, institutionId, iban, currency }),
      }
    );
    const response = await checkResponseStatus(request);
    const { data } = await response.json();
    return data;
  }

  /**
   * Filter expenses based on categories
   * @param {Array<YapilyTransactionModel>} transaction transactions to filter
   * @return {Map<string, YapilyTransactionModel[]>}
   */
  sortExpsensesInCategory(
    transactions: Array<YapilyTransactionModel>
  ): Map<string, YapilyTransactionModel[]> {
    const categories: Map<string, Array<YapilyTransactionModel>> = new Map();

    transactions.forEach((transaction) => {
      try {
        const category = transaction.enrichment.categorisation.categories[0];

        if (!categories.has(category)) {
          categories.set(category, [transaction]);
        } else {
          categories.get(category).push(transaction);
        }
      } catch {
        console.warn("enrichisment not found for transaction:", transaction);
      }
    });

    return categories;
  }

  computeExpsensesOfEachCategory(
    categories: Map<string, YapilyTransactionModel[]>
  ): Map<string, number> {

    const expenses: Map<string, number> = new Map();

    categories.forEach((category, key) => {
      const sum = category
        .map((transaction) => transaction.amount)
        .reduce((p, n) => p + n);
      expenses.set(key, sum);
    });

    return expenses;
  }
}

export default BankaService;

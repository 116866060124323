import { State }  from 'state'
import { NotificationModel } from 'components/models/NotificationModel';

export const updateNotification = (state: State, notificationToUpdate: NotificationModel): State => {
    // Create a shallow copy of the state to avoid mutating the original state
    const newState: State = { ...state };
  
    // Iterate through each notification
    const notificationIndex = newState.notifications.findIndex(notification => notificationToUpdate._id === notification._id);
    if(notificationIndex == -1) {
      newState.notifications.push(notificationToUpdate);
      return newState;
    }
    else {
      newState.notifications[notificationIndex] = notificationToUpdate;
      return newState;
    }
  };

export const setNotificationsInState = (state: State, notifications: NotificationModel[]) => {
    const newState: State = { ...state };
    newState.notifications = notifications;
    
    return newState;
}

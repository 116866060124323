export class WebSocketRequestModel {
  verb: string = "";
  url: string = "";
  private body: string = "";
  private token: string = "";
  private direction: ["incoming" | "outgoing"];

  constructor(verb, url, body, token, direction) {
    this.verb = verb;
    this.url = url;
    this.body = body;
    this.token = token;
    this.direction = direction;
  }

  toString() {
    return JSON.stringify({
      verb: this.verb,
      url: this.url,
      body: this.body,
      token: this.token,
      direction: this.direction
    })
  }
}

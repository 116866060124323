import { IGenericAccount } from "components/models/YapilyModels/IGenericAccount";
import { IGenericTransaction } from "components/models/YapilyModels/IGenericTransaction";
import { WalletHistoryModel } from "services/models/WalletHistoryModel";
import { State } from "state";

/**
 * seful when you’re receiving wallets one by one, for example, in real-time updates.
 * @param state current state
 * @param wallet wallet to add to the state
 * @returns the new state with the new added wallet
 */
export const addWallet = (state: State, wallet: IGenericAccount): State => {
  return { ...state, wallets: [...state.wallets, wallet] };
};

export const updateWallet = (
  state: State,
  walletToAdd: IGenericAccount
): State => {
  // Create a shallow copy of the state to avoid mutating the original state
  const newState: State = { ...state };

  // Find the index of the wallet to update in the state
  const walletIndex = newState.wallets.findIndex(
    (wallet) => walletToAdd.id === wallet.id
  );

  if (walletIndex === -1) {
    // If the wallet doesn't exist, add it to the state
    newState.wallets.push(walletToAdd);
  } else {
    // If the wallet exists, update it
    const existingWallet = newState.wallets[walletIndex];
    const updatedWallet: IGenericAccount = {
      ...existingWallet,
      // Merge transactions from the existing wallet and the new wallet
      transactions: [...existingWallet.transactions, ...(walletToAdd.transactions || [])],
      balance: walletToAdd.balance
    };
    newState.wallets[walletIndex] = updatedWallet;
  }

  return newState;
};


export const deleteWallet = (state: State, walletId: string) => {
  const newState: State = { ...state };
  newState.wallets = newState.wallets.filter(
    (wallet) => !walletId.includes(wallet.id)
  );
  return newState;
};

/**
 * useful when you’re receiving a batch of wallets at once, for example,
 * when initializing the app or fetching updates.
 * @param state current state
 * @param wallets wallets to add to the state
 * @returns the new state with the new added wallets
 */
export const addWallets = (state: State, wallets: IGenericAccount[]): State => {
  return { ...state, wallets: [...wallets] };
};
/**
 * Updated the transactions to the corresponding wallet in the store
 * @param state current state
 * @param transactions list of transaction to be associated via walletId
 * @returns update state with a wallet having now its transactions updated
 */
export const addTransactions = (
  state: State,
  transactions: IGenericTransaction[]
): State => {
  const newState: State = { ...state };

  // Iterate through each transaction and update the corresponding wallet in the state
  transactions.forEach((transaction) => {
    const walletIndex = newState.wallets.findIndex(
      (wallet) => wallet.id === transaction.walletId
    );

    // If the wallet is found, update its transactions
    if (walletIndex !== -1) {
      const updatedWallet = { ...newState.wallets[walletIndex] };
      if (!Array.isArray(updatedWallet.transactions)) {
        updatedWallet.transactions = [];
      }

      // Find the index of the existing transaction if it exists
      const transactionIndex = updatedWallet.transactions.findIndex(
        (currentTransaction) => currentTransaction._id === transaction._id
      );

      if (transactionIndex !== -1) {
        // Replace the existing transaction with the updated one
        updatedWallet.transactions[transactionIndex] = transaction;
      } else {
        // Add the new transaction if it doesn't already exist
        updatedWallet.transactions.push(transaction);
      }

      // Sort the transactions by date
      updatedWallet.transactions.sort((b, a) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

      newState.wallets[walletIndex] = updatedWallet;
    }
  });

  return newState;
};

export const addTransaction = (
  state:State,
  transaction: IGenericTransaction
): State => {
  const newState: State = { ...state };
  const walletIndex = newState.wallets.findIndex(
    (wallet) => transaction.walletPaymail === wallet.identification
  );

  newState.wallets[walletIndex].transactions.push(transaction)

  return newState
}
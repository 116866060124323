import * as dns from 'dns';

async function resolvePaymailOrigin(paymail: string) {
    // Extract domain name from paymail
    const domain = paymail.split('@')[1];

    // Replace domain name in URL by the extracted one
    const url = `https://dns.google.com/resolve?name=_bsvalias._tcp.${domain}&type=SRV&cd=0`;
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Erreur lors de la requête: ${response.statusText}`);
        }
        const data = await response.json();

        if (!data || !data.Answer || data.Answer.length === 0) {
            throw new Error("Aucune donnée de réponse trouvée");
        }
        const answer = data.Answer[0];
        const urlParts = answer.data.split(' ');
        const apiUrl = urlParts[urlParts.length - 1].slice(0, -1); 
		
        return apiUrl;
    } catch (error) {
        // Handle error
        console.error("Une erreur est survenue lors de la résolution du paymail :", error);
        return domain; // Still return domain name
    }
}



interface Capabilities {
	bsvalias: string;
	capabilities: {
		[key: string]: string | boolean | undefined;
		'pki'?: string;
		'paymentDestination'?: string;
		'a9f510c16bde'?: string;
		'f12f968c92d6'?: string;
		'5f1323cddf31'?: string;
		'2a40af698840'?: string;
	};
}
/*
 * get Capabilities from the origin of the paymail
 */
export async function getCabapilities(paymail: string) {
	const origin = await resolvePaymailOrigin(paymail);
	const url = `https://${origin}/.well-known/bsvalias`;

	try {
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});
		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		}
		const data = await response.json();
		if (!data) {
			throw new Error(`Cabapilities not found`);
		}
		return data;
	} catch (error) {
		console.error('Error fetching capabilities:', error);
		throw error; // You might want to handle this error accordingly in your application
	}
}

export async function getPki(paymail: string) {
	try {
		const capabilitiesResponse: Partial<Capabilities> | undefined = await getCabapilities(paymail);

		if (capabilitiesResponse && capabilitiesResponse.bsvalias && capabilitiesResponse.capabilities && typeof capabilitiesResponse.capabilities.pki === 'string') {
			let pkiUrl = capabilitiesResponse.capabilities.pki;
			const bsvalias = paymail.split('@')[0];
			const domainTld = paymail.split('@')[1];
			pkiUrl = pkiUrl.replace('{alias}', bsvalias).replace('{domain.tld}', domainTld);

			const response = await fetch(pkiUrl, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			});

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}

			const pkiData = await response.json();
			return pkiData;
		} else {
			console.error('Invalid or empty capabilities response');
		}
	} catch (error) {
		console.error('Error fetching capabilities:', error);
		throw error;
	}
}

/*
 * get Public Profile from payamil
 */
export async function getPublicProfile(paymail: string) {
	try {
		const capabilitiesResponse: Partial<Capabilities> | undefined = await getCabapilities(paymail);

		if (capabilitiesResponse && capabilitiesResponse.bsvalias && capabilitiesResponse.capabilities && typeof capabilitiesResponse.capabilities.f12f968c92d6 === 'string') {
			let profileUrl = capabilitiesResponse.capabilities.f12f968c92d6;
			const bsvalias = paymail.split('@')[0];
			const domainTld = paymail.split('@')[1];
			profileUrl = profileUrl.replace('{alias}', bsvalias).replace('{domain.tld}', domainTld);

			const response = await fetch(profileUrl, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			});

			if (!response.ok) {
				return response
			}

			const profileData = await response.json();
			return profileData;
		} else {
			console.error('Invalid or empty capabilities response');
		}
	} catch (error) {
		console.error('Error fetching public profile:', error);
		throw error;
	}
}

/*
 * verify pubkey with the paymail of the user
 */
export async function verifyPublicKey(paymail: string, pubkey: string) {
	try {
		const capabilitiesResponse: Partial<Capabilities> | undefined = await getCabapilities(paymail);

		if (capabilitiesResponse && capabilitiesResponse.bsvalias && capabilitiesResponse.capabilities && typeof capabilitiesResponse.capabilities.a9f510c16bde === 'string') {
			let verificationUrl = capabilitiesResponse.capabilities.a9f510c16bde;
			const bsvalias = paymail.split('@')[0];
			const domainTld = paymail.split('@')[1];
			verificationUrl = verificationUrl.replace('{alias}', bsvalias).replace('{domain.tld}', domainTld).replace('{pubkey}', pubkey);

			const response = await fetch(verificationUrl, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			});

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}

			const verificationResult = await response.json();
			return verificationResult;
		} else {
			console.error('Invalid or empty capabilities response');
		}
	} catch (error) {
		console.error('Error verifying public key:', error);
		throw error;
	}
}

export async function sendTransaction(paymail: string, txHex: string, senderName: string, senderPubkey: string, signature: string, note: string, reference: string) {
	try {
		const capabilitiesResponse: Partial<Capabilities> | undefined = await getCabapilities(paymail);

		if (capabilitiesResponse && capabilitiesResponse.bsvalias && capabilitiesResponse.capabilities && typeof capabilitiesResponse.capabilities['5f1323cddf31'] === 'string') {
			const transactionUrl = capabilitiesResponse.capabilities['5f1323cddf31'];
			const bsvalias = paymail.split('@')[0];
			const domainTld = paymail.split('@')[1];

			const requestBody = {
				hex: txHex,
				metadata: {
					sender: senderName,
					pubkey: senderPubkey,
					signature: signature,
					note: note,
				},
				reference: reference,
			};
			const url = transactionUrl.replace('{alias}', bsvalias).replace('{domain.tld}', domainTld);

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(requestBody),
			});

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status} ${await response.text()}`);
			}

			const transactionResult = await response.json();
			return transactionResult;
		} else {
			console.error('Invalid or empty capabilities response');
		}
	} catch (error) {
		console.error('Error sending transaction:', error);
		throw error;
	}
}

export async function requestP2PPaymentDestination(paymail: string, satoshis: number) {
	try {
		const capabilitiesResponse: Partial<Capabilities> | undefined = await getCabapilities(paymail);

		if (capabilitiesResponse && capabilitiesResponse.bsvalias && capabilitiesResponse.capabilities && typeof capabilitiesResponse.capabilities['2a40af698840'] === 'string') {
			const paymentDestinationUrl = capabilitiesResponse.capabilities['2a40af698840'];
			const bsvalias = paymail.split('@')[0];
			const domainTld = paymail.split('@')[1];
			const requestBody = {
				satoshis: satoshis,
			};
			const url = paymentDestinationUrl.replace('{alias}', bsvalias).replace('{domain.tld}', domainTld);

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(requestBody),
			});

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}

			const paymentDestinationResult = (await response.json()) as { reference: string; outputs: Array<{ satoshis: number; script: string }> };
			return { reference: paymentDestinationResult.reference, outputs: paymentDestinationResult.outputs };
		} else {
			console.error('Invalid or empty capabilities response');
			return { reference: 'empty', outputs: [] };
		}
	} catch (error) {
		console.error('Error requesting P2P payment destination:', error);
		return { reference: 'empty', outputs: [] };
		throw error;
	}
}
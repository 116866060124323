import { IGenericAccount } from "components/models/YapilyModels/IGenericAccount";

export class WalletModel implements IGenericAccount {
  __v: number;
  _id: string;
  id?: string;
  balanceSatoshis: number;
  balance: number;
  balance_expected?: number;
  createdAt: string;
  currency: string;
  isFavorite: boolean;
  isDeleted: boolean;
  identification: string;
  name: string;
  paymail: string;
  picture: string;
  updatedAt: string;
  userId: string;
  isBlockchain: boolean;
  xpubIndex: number;
  xpubWif: string;
  isError: boolean = false;
  isAvailableInLocalForage?: boolean = false;
}
